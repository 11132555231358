<template>
<div>
    <div>
        <hr class="rbluehr" />
    </div>
    <div class="reg">
        <el-input placeholder="请输入用户名" v-model="usrdata.name" clearable>
        </el-input>
    </div>
    <div class="reg">
        <el-input placeholder="请输入邀请码" v-model="usrdata.code" clearable>
        </el-input>
    </div>
    <div class="reg">
        <form>
            <el-input placeholder="请输入密码" v-model="usrdata.password" show-password></el-input>
        </form>
    </div>
    <div>
        <el-button class="regbtn reg" type="primary" @click="register" :loading="loading">注册</el-button>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            usrdata: {
                name: "",
                password: "",
                code: "",
            },
        };
    },
    methods: {
        register() {
            this.loading = true;
        },
    },
};
</script>

<style scoped>
.rbluehr {
    border: none;
    height: 2px;
    background-color: rgb(64, 158, 255);
    width: 45vw;
    float: right;
    margin-top: -4px !important;
    z-index: 999;
}

.body {
    margin: 5vw;
}

.regbtn {
    width: 90vw;
}

.reg {
    margin-bottom: 1vh !important;
}
</style>
